import { Fullscreen, FullscreenExit } from '@mui/icons-material';
import { Box, IconButton, Stack, Tooltip, styled as muiStyled } from '@mui/material';
import {
  CellKeyDownEvent,
  FillOperationParams,
  GetContextMenuItemsParams,
  GridApi,
  GridReadyEvent,
  ICellRendererParams,
  IRowNode,
} from 'ag-grid-community';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Container from 'src/components/atoms/Container/Container';
import { CountStatusBar } from 'src/components/molecules/CountStatusBar/CountStatusBar';
import EditAllPartnersButton from 'src/components/molecules/EditAllPartnersButton/EditAllPartnersButton';
import { StorefrontTypeSelect } from 'src/components/molecules/StorefrontTypeSelect/StorefrontTypeSelect';
import { ActionButtons } from 'src/components/organisms/ActionButtons/ActionsButtons';
import { ColumnSettingsManagerButton } from 'src/components/organisms/ActionButtons/ColumnSettingsManagerButton';
import FiltersDropdown from 'src/components/organisms/ActionButtons/FiltersDropdown';
import { CachedImageProvider } from 'src/components/organisms/CachedImage/CachedImage';
import { DataTable } from 'src/components/organisms/DataTable/DataTable';
import { defaultRowGroupOptions } from 'src/components/organisms/DataTable/options/row-group-options';
import { Cell } from 'src/components/organisms/DataTable/types';
import { Module, ScopeEnum } from 'src/domain';
import {
  StyleTimelineRowUpdateEventPayload,
  StyleTimelineRowUpdatedEvent,
} from 'src/domain/events/styletimeline-row-updated.event';
import { StyleTimelineOverviewRow } from 'src/domain/table/style-timeline-overview-row';
import { useBufferTime, useScope, useUnsavedChangesModule } from 'src/hooks';
import { useIsAdmin } from 'src/hooks/auth/useIsAdmin';
import { useIsSuperUser } from 'src/hooks/auth/useIsSuperUser';
import { useDiscardChangesModule } from 'src/hooks/discard-changes/useDiscardChangesModule';
import { useStores } from 'src/hooks/store-selection/queries/useStores';
import { useStyleCategoriesQuery } from 'src/hooks/style-categories/queries/useStyleCategoriesQuery';
import { useStyleTimelineSaveChanges } from 'src/hooks/style-timeline/save-changes/useStyleTimelineSaveChanges';
import { useStyleTimelineSimulationRows } from 'src/hooks/style-timeline/simulation/useStyleTimelineSimulationRows';
import { useStyleTimelineHandleCellValueChanges } from 'src/hooks/style-timeline/useStyleTimelineHandleCellValueChanges';
import { collectionsToMap, mapModelToRow } from 'src/mapping/style-timeline.mapping';
import { generateContextMenuItems } from 'src/utils/ag-grid/getContextMenuItems';
import {
  fillOperationForColumn,
  formatAndSendToClipboard,
  getEmptyValue,
  handleClearCellValue,
  handleCopyValueToVisibleRows,
  handleMarkAsInfinitelyAvailable,
} from 'src/utils/ag-grid/styleTimelineHelper';
import { columns, defaultColDef, globalColumns } from './data/columns';

interface StyleTimelineProps {
  disableGlobalPlanning?: boolean;
  disableStorefrontTypeSelect?: boolean;
  disableActionButtons?: boolean;
  storefrontType?: string;
  header?: React.ReactNode;
}

export const StyleTimeline: FC<StyleTimelineProps> = ({
  header,
  disableGlobalPlanning = false,
  disableStorefrontTypeSelect = false,
  disableActionButtons = false,
  storefrontType,
}) => {
  const { data: stores } = useStores();
  const {
    data: overviewRows,
    loading: rowsLoading,
    collections,
    updateCache,
  } = useStyleTimelineSimulationRows(storefrontType);
  const { data: styleCategories } = useStyleCategoriesQuery();
  const [fullscreen, setFullscreen] = useState(false);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const isSuperUser = useIsSuperUser();
  const isAdmin = useIsAdmin();
  const scope = useScope();
  const isGlobalPlanning = useMemo(() => scope === ScopeEnum.GLOBAL, [scope]);

  const dataTableWrapperRef = useRef<HTMLDivElement | null>(null);
  const handleCellValueChanges = useStyleTimelineHandleCellValueChanges(overviewRows);
  const [fillOperationBufferedCells, pushToFillOperationBuffer] = useBufferTime<Cell>(10);

  useEffect(() => {
    if (fillOperationBufferedCells.length > 0 && typeof handleCellValueChanges === 'function') {
      handleCellValueChanges(fillOperationBufferedCells);
    }
    // don't react to handleCellValuesChanged
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fillOperationBufferedCells]);

  const fillOperation = useCallback(
    (params: FillOperationParams) => fillOperationForColumn(params, pushToFillOperationBuffer),
    [pushToFillOperationBuffer]
  );

  const rowGroupOptions = useMemo(() => {
    return {
      ...defaultRowGroupOptions,
      groupDefaultExpanded: isGlobalPlanning ? 1 : 2,
      groupRemoveSingleChildren: false,
      getRowHeight: isGlobalPlanning ? undefined : defaultRowGroupOptions.getRowHeight,
    };
  }, [isGlobalPlanning]);

  useEffect(() => {
    // Note: For performance reasons, we're not using Apollo cache for styletimeline global planning, thus we need to manually update the grid with the data from the server.
    const handleRowUpdate = (event: Event) => {
      const { rows } = (event as CustomEvent<StyleTimelineRowUpdateEventPayload>).detail;
      if (rows) {
        const collectionMap = collectionsToMap(collections);
        const mappedRows = rows.map((row) => mapModelToRow(row, collectionMap, stores.allStores, styleCategories));
        updateCache(mappedRows);
      }
    };

    window.addEventListener(StyleTimelineRowUpdatedEvent, handleRowUpdate);
    return () => {
      window.removeEventListener(StyleTimelineRowUpdatedEvent, handleRowUpdate);
    };
  }, [collections, styleCategories, updateCache, stores.allStores]);

  const copyValueToVisibleRows = useCallback(
    (params: GetContextMenuItemsParams) => handleCopyValueToVisibleRows(params, handleCellValueChanges),
    [handleCellValueChanges]
  );

  const clearCellValue = useCallback(
    (params: Partial<GetContextMenuItemsParams>) => handleClearCellValue(params, getEmptyValue, handleCellValueChanges),
    [handleCellValueChanges]
  );

  const markAsInfinitelyAvailable = useCallback(
    (params: GetContextMenuItemsParams) => handleMarkAsInfinitelyAvailable(params, handleCellValueChanges),
    [handleCellValueChanges]
  );

  const handleCellKeyDown = useCallback(
    (event: CellKeyDownEvent) => {
      if (!event.event) {
        return;
      }
      const keyboardEvent = event.event as unknown as KeyboardEvent;

      const path = event.eventPath;
      const isInputElement = path?.some((element) => element instanceof HTMLInputElement);

      if (['Backspace', 'Clear', 'Delete'].includes(keyboardEvent.key) && !isInputElement) {
        event.event.preventDefault();
        clearCellValue({ column: event.column, node: event.node })();
      }
    },
    [clearCellValue]
  );

  const handleGridReady = useCallback((event: GridReadyEvent) => {
    setGridApi(event.api);
  }, []);

  const getContextMenuItems = useCallback(
    (params: GetContextMenuItemsParams) =>
      generateContextMenuItems(params, clearCellValue, copyValueToVisibleRows, markAsInfinitelyAvailable),
    [clearCellValue, copyValueToVisibleRows, markAsInfinitelyAvailable]
  );

  const doesExternalFilterPass = useCallback((node: IRowNode<StyleTimelineOverviewRow>): boolean => {
    return !node.data?.storePlacement || (!node.data?.exitWeek && node.data?.exitWeekType !== 'INFINITE');
  }, []);

  const [saveChanges, { isSaving }] = useStyleTimelineSaveChanges(storefrontType);
  const [unsavedChanges] = useUnsavedChangesModule<StyleTimelineOverviewRow[]>(Module.StyleTimeline);
  const hasUnsavedChanges = !!unsavedChanges && unsavedChanges.length > 0;
  const discardChanges = useDiscardChangesModule();

  const loading = rowsLoading || isSaving;

  return (
    <Container
      style={{
        height: '100%',
        zIndex: fullscreen ? 3 : 0,
        minHeight: 750,
        position: fullscreen ? 'fixed' : 'static',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: 'auto',
      }}
    >
      <Stack height="100%">
        <Stack
          direction="row"
          sx={{
            marginBottom: 4,
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
            gap: 2,
            '@media (min-width: 1550px)': {
              justifyContent: 'space-between',
            },
          }}
          mb={2}
          alignItems="center"
        >
          {header && (
            <Stack marginRight="auto" justifyContent={'center'}>
              {header}
            </Stack>
          )}
          {scope !== ScopeEnum.STORE && !disableStorefrontTypeSelect && <StorefrontTypeSelect />}
          <Box ml={'auto'}>
            <ActionButtons
              showDiscardButton={!disableActionButtons}
              showSaveButton={!disableActionButtons}
              discardDisabled={!hasUnsavedChanges}
              loading={loading}
              saveLoading={isSaving}
              saveDisabled={!hasUnsavedChanges}
              onDiscard={discardChanges}
              onSave={saveChanges}
            >
              <IconButton color="primary" onClick={() => setFullscreen((prev) => !prev)}>
                {!fullscreen ? (
                  <Tooltip title="Open fullscreen">
                    <Fullscreen />
                  </Tooltip>
                ) : (
                  <Tooltip title="Close fullscreen">
                    <FullscreenExit />
                  </Tooltip>
                )}
              </IconButton>
              <FiltersDropdown gridApi={gridApi} />
              <ColumnSettingsManagerButton gridApi={gridApi} />
              {!disableGlobalPlanning && (isSuperUser || isAdmin) && <EditAllPartnersButton />}
            </ActionButtons>
          </Box>
        </Stack>
        <TableWrapper ref={dataTableWrapperRef} className="ag-theme-alpine-custom-compact small-font" flex={1}>
          <CachedImageProvider>
            <DataTable
              {...rowGroupOptions}
              fillOperation={fillOperation}
              sendToClipboard={formatAndSendToClipboard}
              rows={loading ? undefined : overviewRows}
              columns={isGlobalPlanning ? globalColumns : columns}
              defaultColDef={defaultColDef}
              domLayout="normal"
              onGridReady={handleGridReady}
              groupDisplayType="groupRows"
              doesExternalFilterPass={doesExternalFilterPass}
              groupRowRendererParams={{
                innerRenderer: GroupRowInnerRenderer,
                suppressDoubleClickExpand: true,
                suppressEnterExpand: true,
              }}
              onCellKeyDown={handleCellKeyDown}
              isNumberTable={false}
              animateRows={true}
              getContextMenuItems={getContextMenuItems}
              onCellValuesChanged={handleCellValueChanges}
              loading={loading}
              gridOptions={{
                statusBar: {
                  statusPanels: [
                    {
                      statusPanel: CountStatusBar,
                      align: 'right',
                    },
                  ],
                },
              }}
              sideBar={{
                position: 'left',
                toolPanels: [
                  {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                    toolPanelParams: {
                      suppressExpandAll: false,
                    },
                  },
                  {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    toolPanelParams: {
                      suppressExpandAll: false,
                    },
                  },
                ],
              }}
            />
          </CachedImageProvider>
        </TableWrapper>
      </Stack>
    </Container>
  );
};

const TableWrapper = muiStyled(Box)`
  .ag-root-wrapper {
    background-color: #fafafa;
  }

  .ag-row {
    background-color: #fff;
  }

  .ag-pivot-mode-panel,
  .ag-last-column-drop {
    display: none;
  }
`;

export const GroupRowInnerRenderer: FC<ICellRendererParams> = ({ node }) => {
  return (
    <Box width={'100%'} fontWeight={'bold'}>
      {node.key}
    </Box>
  );
};
