import { useReactiveVar } from '@bestseller-bit/retail-planning.utils.reactive-vars';
import { useMemo } from 'react';
import { Module } from 'src/domain';
import { selectedInheritanceMapVar } from 'src/infrastructure/local_state';
import { useCurrentId } from '../useCurrentId';
import { useCurrentModule } from '../useCurrentModule';
import { useScope } from '../useScope';
import { useSessionStorageInheritance } from './useStoredInheritance';

export const useSelectedInheritance = (selectedModule?: Module) => {
  const [selectedInheritanceMap] = useReactiveVar(selectedInheritanceMapVar);
  const scope = useScope();
  const id = useCurrentId();
  const currentModule = useCurrentModule();
  const module = selectedModule ?? currentModule;

  const { inheritance: sessionStorageInheritance } = useSessionStorageInheritance(module);

  return useMemo(() => {
    if (!scope || !id || !module) return;
    return selectedInheritanceMap.get({ module: module, scope, id }) ?? sessionStorageInheritance;
  }, [id, selectedInheritanceMap, module, scope, sessionStorageInheritance]);
};
