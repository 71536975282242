import { useReactiveVar } from '@bestseller-bit/retail-planning.utils.reactive-vars';
import { useCallback } from 'react';
import { Inheritance, InheritanceError } from 'src/domain';
import { selectedInheritanceMapVar } from 'src/infrastructure/local_state';
import { SerializeMap } from 'src/utils/SerializeMap';
import { useCurrentId } from '../useCurrentId';
import { useCurrentModule } from '../useCurrentModule';
import { useScope } from '../useScope';
import { useSessionStorageInheritance } from './useStoredInheritance';

export const useSetSelectedInheritance = () => {
  const module = useCurrentModule();
  const id = useCurrentId();
  const scope = useScope();
  const [_, setSelectedInheritanceMap] = useReactiveVar(selectedInheritanceMapVar);

  const { setSessionStorageInheritance } = useSessionStorageInheritance();

  const setInheritance = useCallback(
    (inheritance: Inheritance) => {
      if (!scope) throw new InheritanceError('Could not find current split level');
      if (!module) throw new InheritanceError('Could not find current module');
      if (typeof id !== 'number') throw new InheritanceError('Could not find current id');

      setSessionStorageInheritance(inheritance);

      // we need to ensure new reference for reactive var to trigger
      setSelectedInheritanceMap((prev) => {
        const newMap = new SerializeMap(prev);
        newMap.set({ module, scope, id }, inheritance);
        return newMap;
      });
    },
    [scope, module, id, setSessionStorageInheritance, setSelectedInheritanceMap]
  );

  return setInheritance;
};
