import { isEmpty, isEqual } from 'lodash';
import { useMemo, useState, useSyncExternalStore } from 'react';
import { useSnackbar } from './snackbar/useSnackbar';

export const useSessionStorage = <T>(key: string, defaultValue: T): [T, (value: T | ((newValue: T) => T)) => void] => {
  const [cachedValue, setCachedValue] = useState(getContentInSessionStorage(key, defaultValue));

  const store = useMemo(
    () => ({
      getSnapshot: () => {
        const freshData = getContentInSessionStorage(key, defaultValue);
        // Cache data in order to avoid unnecessary re-renders

        if (!isEqual(cachedValue, freshData)) {
          setCachedValue(freshData);
        }
        return cachedValue;
      },
      subscribe: (listener: () => void) => {
        window.addEventListener('storage', listener);
        return () => window.removeEventListener('storage', listener);
      },
    }),
    [cachedValue, defaultValue, key]
  );

  const value = useSyncExternalStore(store.subscribe, store.getSnapshot);
  const showSnackbar = useSnackbar();

  const setValue = (value: T | ((newValue: T) => T)): void => {
    try {
      const newValue = JSON.stringify(value);
      window.sessionStorage.setItem(key, newValue);
      window.dispatchEvent(new StorageEvent('storage', { key, newValue }));
    } catch (error: unknown) {
      showSnackbar(
        'Operation failed. Too many active changes. Try to make changes in smaller batches by saving before retrying the operation again.',
        'error'
      );

      // Log the error to get statistics in datadog
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return [value, setValue];
};

export const getContentInSessionStorage = <T>(key: string, defaultValue: T) => {
  try {
    const item = window.sessionStorage.getItem(key);
    if (item) {
      return JSON.parse(item) as T;
    } else {
      if (!isEmpty(defaultValue)) {
        window.sessionStorage.setItem(key, JSON.stringify(defaultValue));
      }
      return defaultValue;
    }
  } catch (error: unknown) {
    // eslint-disable-next-line no-console
    console.error(error);

    return defaultValue;
  }
};
